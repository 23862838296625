.home-page {
  background-color: #f6f6ef;
  font-family: Verdana, Geneva, sans-serif;
}

.app-title {
  color: #ff6600;
  font-size: 2em;
  margin-bottom: 1em;
}

.chat-container {
  border-color: #ff6600;
}

.messages-container {
  height: 70vh;
  padding: 1em;
  background-color: #ffffff;
  border-bottom: 1px solid #ff6600;
}

.messages-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;
}

.message-human {
  align-self: flex-end;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-text {
  color: #333333;
  font-size: 1.2em;
}

.message-bubble {
  padding: 0.6em;
  border-radius: 0.4em;
}

.bubble-human {
  background-color: #d9e9ff;
}

.bubble-ai {
  background-color: #e6e6e6;
}

.message-sender {
  font-size: 0.8em;
  color: #888888;
  align-self: flex-end;
}

.sender-human {
  color: #007aff;
}

.input-form {
  padding: 1em;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ff6600;
}

.input-field {
  width: 85%;
  padding: 0.8em;
  border: none;
  border-radius: 0.4em;
}

.submit-btn {
  padding: 0.8em 1.2em;
  border: none;
  border-radius: 0.4em;
  background-color: #ff6600;
  color: #ffffff;
}

.gradient-text {
  background: linear-gradient(45deg, red, rgb(251, 0, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}